<template>
  <ul>
    <li
      v-for="item in items"
      :key="item.id"
      class="bfy-level"
    >
      <div class="bfy-level-left">
        <driver-list-item :item="item" />
      </div>
      <div class="bfy-level-right">
        <b-button type="is-info" size="is-small is-circle" icon-left="chevron-right"
                  outlined
                  @click="showList(item.id)"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import DriverListItem from '@/components/Board/DriverListItem'

export default {
  name: 'DriverList',
  components: { DriverListItem },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    showList (driverId) {
      this.$parent.$parent.showTransferList(driverId, null)
    }
  }
}
</script>
